import React from 'react';

import Layout from '../components/Layout';

const NotFound = () => {
  return (
    <Layout>
      <div>Not Found 404</div>
    </Layout>
  );
}

export default NotFound;
